import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Suspense, lazy } from "react";
import { PlusCircle } from "@hl/base-components/lib/assets/icons.generated";
import { Modal, Button, Loader, Center, useMantineTheme } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
const CreateNFTContent = lazy(() => import("./CreateNFTContent"));
const CreateNftModal = ({ opened, onClose, }) => {
    const theme = useMantineTheme();
    const isMd = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
    return (_jsx(Modal, { opened: opened, onClose: onClose, title: "Create an NFT", size: !isMd ? "100%" : 800, centered: true, styles: {
            root: {
                zIndex: 400,
            },
            modal: {
                minHeight: !isMd ? "auto" : 500,
            },
            body: {
                height: "inherit",
                paddingLeft: 4,
            },
        }, children: _jsx(Suspense, { fallback: _jsx(Center, { p: "xl", children: _jsx(Loader, { size: "md" }) }), children: _jsx(CreateNFTContent, {}) }) }));
};
export const CreateNftButton = ({ isMobile, }) => {
    const [opened, { open, close }] = useDisclosure(false);
    return (_jsxs(_Fragment, { children: [_jsx(CreateNftModal, { opened: opened, onClose: close, isMobile: isMobile }), _jsx(Button, { onClick: open, leftIcon: isMobile ? null : _jsx(PlusCircle, {}), sx: { paddingLeft: 12, paddingRight: 12 }, children: "Create" })] }));
};
