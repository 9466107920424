import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import MultilineText from "@hl/base-components/lib/MultilineText";
import { CheckImported, CheckVerified, } from "@hl/base-components/lib/assets/icons.generated";
import { UTILITY_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD, WEIGHT_NORMAL, } from "@hl/base-components/lib/theme/typography";
import { Center, Group, useMantineTheme, Flex, } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import ProfilePlaceholderImage from "../../assets/images/profile-placeholder.png";
import { USER_ROUTES } from "../../config";
import { getProfileDisplayName } from "../../utils/profile";
import { ResizedImage } from "../image";
import { usePublicUserProfileQuery } from "./queries/user.graphql.generated";
export const User = ({ avatarUrl, displayName, walletAddresses, enableLink = true, newTab = true, bold, verified, imported, small, imageClass, avatarSize = 20, checkIconSize = 20, textSize = "sm", numLines = "2", textColor, textAlign, 
// sx,
vertical = false, shortenAddress = true, leadingText, }) => {
    var _a;
    // next.js loads images differently
    const defaultImgPath = (_a = ProfilePlaceholderImage.src) !== null && _a !== void 0 ? _a : ProfilePlaceholderImage;
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
    const profileName = (_jsxs(Flex, { direction: vertical ? "column" : "row", align: "center", gap: small ? 4 : 6, wrap: "nowrap", onClick: () => {
            if (enableLink) {
                const link = (walletAddresses === null || walletAddresses === void 0 ? void 0 : walletAddresses.length)
                    ? USER_ROUTES.user.profile.replace(":slug", walletAddresses[0])
                    : "";
                if (link) {
                    window.open(link, newTab ? "_blank" : undefined);
                }
            }
        }, sx: {
            ":hover": {
                cursor: enableLink ? "pointer" : undefined,
            },
        }, children: [!small && (_jsx(ResizedImage, { src: avatarUrl || defaultImgPath, fit: "fill", radius: 999, mih: avatarSize, miw: avatarSize, height: avatarSize, width: avatarSize, sx: (theme) => ({
                    border: `0.5px solid ${theme.fn.themeColor(UTILITY_COLOR.DIVIDER)}`,
                    borderRadius: 999,
                    boxSizing: "unset",
                }), className: imageClass })), _jsxs(Group, { align: "center", spacing: small ? 4 : 6, noWrap: true, children: [_jsxs(MultilineText, { size: textSize, color: textColor, fw: bold ? WEIGHT_BOLD : WEIGHT_NORMAL, numLines: numLines, ta: textAlign !== null && textAlign !== void 0 ? textAlign : "center", children: [!!leadingText && _jsxs(_Fragment, { children: [leadingText, " "] }), getProfileDisplayName(displayName, walletAddresses, "", shortenAddress && isMobile)] }), (verified || imported) && (_jsx(Center, { sx: { color: "black" }, children: verified ? (_jsx(CheckVerified, { height: checkIconSize, width: checkIconSize })) : (_jsx(CheckImported, { height: checkIconSize, width: checkIconSize })) }))] })] }));
    return profileName;
};
export const UserResolved = ({ avatarUrl, displayName, walletAddresses, verified, imported, ...props }) => {
    var _a;
    const { data } = usePublicUserProfileQuery({
        variables: {
            slug: (_a = walletAddresses === null || walletAddresses === void 0 ? void 0 : walletAddresses[0]) !== null && _a !== void 0 ? _a : "",
        },
        skip: !(walletAddresses === null || walletAddresses === void 0 ? void 0 : walletAddresses.length),
        notifyOnNetworkStatusChange: true,
    });
    return (_jsx(User, { ...props, avatarUrl: avatarUrl || (data === null || data === void 0 ? void 0 : data.getPublicAccountSettings.displayAvatar), displayName: displayName || (data === null || data === void 0 ? void 0 : data.getPublicAccountSettings.displayName), walletAddresses: walletAddresses || (data === null || data === void 0 ? void 0 : data.getPublicAccountSettings.walletAddresses), verified: verified !== null && verified !== void 0 ? verified : !!(data === null || data === void 0 ? void 0 : data.getPublicAccountSettings.verified), imported: imported !== null && imported !== void 0 ? imported : !!(data === null || data === void 0 ? void 0 : data.getPublicAccountSettings.imported) }));
};
