import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { OUTLINE_COLOR, SECONDARY_COLOR, } from "@hl/base-components/lib/theme/button";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { formatNumber } from "@hl/base-components/lib/utils/numbers";
import { Button, Text } from "@mantine/core";
import { NftContractStandard } from "../../../apollo/graphql.generated";
import { getCurrencySymbol } from "../../../utils/currency";
import { useAuth } from "../../auth/AuthContext";
import { useModalStack } from "../../modal";
import BidModalHighlight from "../modals/BidModal";
import BuyModalHighlight from "../modals/BuyModal";
const BidBuyButtons = ({ token, collection, onCompleted = () => null, compact, hideOfferBtn, }) => {
    var _a, _b, _c;
    const { walletAddress } = useAuth();
    const { pushModal } = useModalStack();
    const { ownerAddress, userBalance, balance } = token;
    const has1155Balance = userBalance && userBalance > 0;
    const ownsAllBalance = !!userBalance && userBalance === balance;
    const canBuy = collection.standard === NftContractStandard.ERC1155 && !ownsAllBalance;
    const isOwner = ((walletAddress === null || walletAddress === void 0 ? void 0 : walletAddress.toLowerCase()) &&
        (ownerAddress === null || ownerAddress === void 0 ? void 0 : ownerAddress.toLowerCase()) === (walletAddress === null || walletAddress === void 0 ? void 0 : walletAddress.toLowerCase())) ||
        has1155Balance;
    const buttonProps = compact
        ? {
            size: "xs",
            px: 8,
        }
        : {
            size: "sm",
            px: 12,
        };
    if (isOwner && !canBuy)
        return null;
    if (token.tokenListing) {
        return (_jsx(Button, { ...buttonProps, color: OUTLINE_COLOR, onClick: () => {
                var _a;
                return pushModal(_jsx(BuyModalHighlight, { tokenId: token.tokenId, src: token.imageUrl || "", collectionData: collection, orderId: (_a = token.tokenListing) === null || _a === void 0 ? void 0 : _a.reservoirId, onCompleted: onCompleted }), {
                    size: "lg",
                    title: (_jsx(Text, { size: "md", fw: WEIGHT_BOLD, children: "Complete purchase" })),
                });
            }, children: _jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: `${formatNumber((_c = (_b = (_a = token.tokenListing) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.amount) === null || _c === void 0 ? void 0 : _c.native)} ${getCurrencySymbol(collection.chainId || "")}` }) }));
    }
    return (_jsx(Button, { color: SECONDARY_COLOR, sx: { opacity: hideOfferBtn ? 0 : 1, transition: "opacity 0.1s" }, ...buttonProps, onClick: () => pushModal(_jsx(BidModalHighlight, { tokenId: token.tokenId, src: token.imageUrl || "", collectionData: collection, onCompleted: onCompleted }), {
            size: "lg",
            title: _jsx(Text, { fw: WEIGHT_BOLD, children: "Make an offer" }),
        }), children: "Make offer" }));
};
export default BidBuyButtons;
