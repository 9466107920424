import { Center, Loader } from "@mantine/core";

export const SHLOMS_404_FALLBACK_IMG_URL =
  "https://highlight-creator-assets-prod.s3.amazonaws.com/main/image/404.png";
export const SHLOMS_404_FALLBACK_IMG = (
  <Center>
    <Loader />
  </Center>
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const shloms404OnImageError = (object: any) => {
  object.target.onerror = null;
  object.target.src = SHLOMS_404_FALLBACK_IMG_URL;
  object.target.srcset = SHLOMS_404_FALLBACK_IMG_URL;
};
