import {
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useRef,
} from "react";

import {
  Gift as SponsoredIcon,
  LockFill,
} from "@hl/base-components/lib/assets/icons.generated";
import { Cryptocurrency01 } from "@hl/base-components/lib/assets/icons.generated/HDS/Duotone icons/Finance & eCommerce";
import {
  AFFIRMATIVE_COLOR,
  OUTLINE_COLOR,
} from "@hl/base-components/lib/theme/button";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { ampli } from "@hl/shared-features/lib/ampli";
import { UnlockGateResult } from "@hl/shared-features/lib/apollo/graphql.generated";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import { SignInButton } from "@hl/shared-features/lib/features/auth/SignInButton";
import { useEmbedMode } from "@hl/shared-features/lib/features/auth/hooks";
import AddToCalendarButton from "@hl/shared-features/lib/features/calendar/AddToCalendarButton";
import { LIFECYCLE_ERROR_CODES } from "@hl/shared-features/lib/features/evm-tx";
import {
  pendingStates,
  TransactionStateType,
  useTransactionState,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { GateConditionsModalProps } from "@hl/shared-features/lib/features/gate/GateConditionsModalBody";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import {
  isTestnetNetwork,
  networkLookup,
} from "@hl/shared-features/lib/utils/blockExplorer";
import {
  getCurrencyColor,
  getCurrencySymbol,
  getERC20CurrencyIcon,
} from "@hl/shared-features/lib/utils/currency";
import {
  Button,
  ButtonProps,
  createStyles,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { useCollections, useTokens } from "@reservoir0x/reservoir-kit-ui";
import { ethers } from "ethers";

import { ReactComponent as OpenSeaIcon } from "~assets/icons/opensea.svg";
import ApproveERC1155Button from "~features/MintPage/MintVector/ApproveERC1155Button";
import ApproveErc20Button from "~features/MintPage/MintVector/ApproveErc20Button";
import CrossChainMintButton from "~features/MintPage/MintVector/CrossChainMintButton";
import { AppendNumMintsInput } from "~features/MintPage/MintVector/NumMintsInput";
import useMintState, { MintStateRequired } from "~hooks/useMintState";
import useMintTokensState from "~hooks/useMintTokensState";

import { CollectionStatus } from "../../../apollo/graphql.generated";
import {
  ModalType,
  resetModalVar,
  updateModalVar,
} from "../../layout/Modal/modal";
import CollectorsChoiceMarketplaceModal from "../../marketplace/CollectorsChoiceMarketplaceModal";
import { SHLOMS_404_FALLBACK_IMG_URL } from "../custom/Shloms404";
import useCardSaleData from "../hooks/useCardSaleData";
import { GetSalePageMintVectorsDocument } from "../queries.graphql.generated";
import useLifecycleWarningsButtonHandler from "../useLifecycleWarningsButtonHandler";
import { SaleStatus, UserStatus } from "../utils/types";

import ErrorBox from "./ErrorBox";
import { Collection, MintVector } from "./MintCard";
import MintFee from "./MintFee";
import { transactionStateErrorMsg } from "./tx-state";

export enum MintButtonPosition {
  MintCard = "MintCard",
  BottomBar = "BottomBar",
  BrowsePageToken = "BrowsePageToken",
  Marketplace = "Marketplace",
  SeriesPanel = "SeriesPanel",
  TokenDetails = "TokenDetail",
}
export type CardButtonProps = {
  isTokenSold?: boolean;
  tokenId?: string | null;
  mintLabel?: string;
  onMintClick: (fromChain: number) => void;
  showErrorIcon?: boolean;
  disabledMint?: boolean | null;
  showMintFee?: boolean;
  onMinted?: () => void;
  showGateRequirementsModal?: boolean;
  showWarningModals?: boolean;
  showConnectButton?: boolean;
  buttonPosition: MintButtonPosition;
  enableCrossChainMint?: boolean;
  showLongCreditCardMintLabel?: boolean;
  isApollo?: boolean;
  showNumberMintsInput?: boolean;
} & Partial<ButtonProps>;

export const stateLabels: { [key: string]: string } = {
  [TransactionStateType.WaitingSignedTx]: "Waiting for user signature",
};

export const useMintCardButtonStyles = createStyles(
  (theme, { isApollo }: { isApollo?: boolean }) => ({
    buttonContainer: {
      // So the button grows when inside the mint card or standalone
      flex: 1,
      // Avoid long flex items from overflowing the container
      ...(!isApollo && { minWidth: 0 }),
      alignItems: "flex-end",
    },
  })
);

const showAllGatePrereqs = (
  result: UnlockGateResult,
  creatorAddress?: string | null,
  creatorEns?: string | null
) => {
  return () => {
    updateModalVar<GateConditionsModalProps>({
      showModal: ModalType.GATED_ENTITY_ALL_PREREQS,
      data: {
        creatorAddress,
        creatorEns,
        refetchOnRequirementChange: [GetSalePageMintVectorsDocument],
        result,
        handleDone: () => resetModalVar(),
        entity: "mint",
        isPreview: false,
      },
    });
  };
};

export const handleShowGateRequirementsModal = (
  collection: Collection,
  mintVector?: MintVector
) =>
  mintVector?.userGateAccess
    ? showAllGatePrereqs(
        mintVector?.userGateAccess,
        collection.creatorAddresses
          ? collection.creatorAddresses[0].address
          : undefined,
        collection.creatorEns
      )
    : undefined;

const MintCardButton = forwardRef(
  (
    {
      enableCrossChainMint,
      mintLabel,
      onMintClick,
      showErrorIcon = true,
      disabledMint,
      showMintFee,
      showGateRequirementsModal,
      showWarningModals = false,
      tokenId,
      isTokenSold = false,
      showConnectButton = true,
      buttonPosition,
      onMinted,
      showLongCreditCardMintLabel,
      isApollo,
      showNumberMintsInput = false,
      ...rest
    }: CardButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const mintState = useMintState() as MintStateRequired;
    const mintPageTokens = useMintTokensState();
    const {
      saleStatus,
      collection,
      txnId,
      mintVector,
      numTokensToMint,
      chainId,
      chain,
      numMintsLeft,
      collectionStatus,
      isSoldOut,
      userStatus,
      price,
      isCollectorChoiceMint,
      mintingSeriesTokenId,
      tokens,
      isAnySeriesMint,
      isMarketplaceEnabledForCollectionChain,
      reservoirCollectionId,
      hasSufficientAllowance,
      isErc20CurrencyMint,
      enableBurnAndRedeem,
      hasEnoughToBurnAndRedeem,
      mintFeeWaived,
      isSponsoredMint,
    } = mintState;
    // Set button styles from currency
    rest.color = getCurrencyColor(mintVector.paymentCurrency, chainId);
    rest.leftIcon = getERC20CurrencyIcon(mintVector.paymentCurrency, chainId);

    // TODO JJ: move to a hook

    const fetchMpData =
      isMarketplaceEnabledForCollectionChain && isAnySeriesMint;
    const { mutate: mutateMarketplaceTokens } = useTokens(
      fetchMpData
        ? {
            collection: reservoirCollectionId,
            limit: 16,
          }
        : undefined
    );
    const { mutate: mutateCollections } = useCollections(
      fetchMpData
        ? {
            id: reservoirCollectionId,
          }
        : undefined
    );

    const { pushModal, popModal } = useModalStack();

    const isCompact = rest.size === "sm";
    const mintLabelPrefix = isCompact ? "" : "Mint · ";
    const mintLabelText = mintVector.sponsored
      ? "Claim"
      : mintLabel ||
        (price !== "0"
          ? `${mintLabelPrefix}${price} ${getCurrencySymbol(
              mintVector.chainId || "",
              mintVector.paymentCurrency?.symbol
            )}`
          : !!collection.creditCardEnabled &&
            mintVector.currency === ethers.constants.AddressZero
          ? "Buy with ETH"
          : "Mint");
    const resolvedMintLabel: ReactNode = showLongCreditCardMintLabel ? (
      <Group spacing={8} noWrap>
        <Cryptocurrency01 width={16} height={16} /> Buy with crypto
      </Group>
    ) : (
      mintLabelText
    );

    const isLocked =
      !!mintVector?.gateId &&
      (!mintVector?.userGateAccess || !mintVector?.userGateAccess?.passed);
    const { hasEnoughMoney } = useCardSaleData({
      mintVector,
      numTokens: numTokensToMint || 1,
    });
    const transactionState = useTransactionState(txnId);

    const { authenticated } = useAuth();

    const { classes } = useMintCardButtonStyles({ isApollo: !!isApollo });
    const artist =
      collection.creatorAccountSettings?.displayName ??
      collection.creatorEns ??
      (collection.creatorAddresses ?? [])[0]?.address ??
      "";
    const actionId = useRef<string | null>(null);
    const mintVectorId = mintVector.id!;
    const { isEmbedMode: isEmbed } = useEmbedMode();

    const handleMintClick = useCallback(
      (fromChain: number, isRetry?: boolean) => {
        if (ampli.isLoaded) {
          const network = networkLookup(chainId);
          (actionId.current = `mint-${Date.now()}`),
            ampli.mintStart({
              projectName: collection.name,
              artist,
              collectionId: collection.id,
              mintVectorId,
              tokenId: tokenId ?? undefined,
              network: network.type,
              isTestnet: isTestnetNetwork(network.type),
              mintButtonType: buttonPosition,
              price: price,
              quantity: numTokensToMint,
              pagePath: window.location.pathname,
              isRetry,
              actionId: actionId.current,
              isEmbed,
            });
        }
        onMintClick(fromChain);
      },
      [onMintClick, numTokensToMint, collection, tokenId, price, chainId]
    );
    const trackViewOnMarketplace = useCallback(() => {
      if (!ampli.isLoaded) {
        return;
      }
      ampli.viewOnMarketplace({
        collectionId: collection.id,
        projectName: collection.name,
        marketplace: isAnySeriesMint ? "Highlight" : "OpenSea",
        pagePath: window.location.pathname,
      });
    }, [collection, isAnySeriesMint]);

    const { onClickHandler } = useLifecycleWarningsButtonHandler({
      txnId,
      hasEnoughMoney,
      userStatus,
      showWarningModals,
      onClick: handleMintClick,
    });

    const insufficientFundsError =
      transactionState?.error?.details?.includes("insufficient funds") ||
      transactionState?.error?.cause?.reason?.includes(
        "amount exceeds balance"
      );

    const insuficientAllowanceError =
      !!transactionState?.error?.cause?.reason?.includes(
        "insufficient allowance"
      );

    const showApproveErc20Button =
      isErc20CurrencyMint && !hasSufficientAllowance;

    const txStateType = transactionState?.type;
    // track mint finish
    useEffect(() => {
      if (
        !txStateType ||
        !transactionState ||
        !actionId.current ||
        pendingStates.includes(txStateType)
      ) {
        return;
      }
      if (!ampli.isLoaded) {
        return;
      }
      const network = networkLookup(chainId);
      const isSuccess = txStateType === TransactionStateType.Done;
      ampli.mintFinish({
        projectName: collection.name,
        artist,
        collectionId: collection.id,
        mintVectorId,
        tokenId: tokenId ?? undefined,
        network: network.type,
        isTestnet: isTestnetNetwork(network.type),
        mintButtonType: buttonPosition,
        price: price,
        quantity: numTokensToMint,
        pagePath: window.location.pathname,
        txStateType,
        status: isSuccess ? "success" : "error",
        ...(!isSuccess && {
          errorMessage:
            transactionState?.error?.details ||
            transactionState?.error?.message ||
            transactionState?.error?.toString() ||
            "unknown error",
        }),
        actionId: actionId.current,
        isEmbed,
      });
      if (isSuccess) {
        onMinted?.();
      }

      // this ensures that event is tracked only once
      actionId.current = null;
    }, [txStateType, isEmbed, onMinted]);

    const openMarketplaceModalHandler = useCallback(() => {
      pushModal(
        <CollectorsChoiceMarketplaceModal
          mintState={mintState}
          mintPageTokens={mintPageTokens}
        />,
        {
          size: "100%",
          title: <Text fw={WEIGHT_BOLD}>Select a token</Text>,
          onClose: () => {
            mutateMarketplaceTokens();
            mutateCollections();
            popModal();
          },
          styles: {
            header: {
              marginBottom: 0,
            },
          },
        }
      );
    }, [collection]);

    const errorMsg = transactionStateErrorMsg(transactionState);

    const LockedBody = (
      <Group spacing={isCompact ? 8 : 10} noWrap>
        <LockFill />
        <span>{isCompact ? "Locked" : "Mint Locked"}</span>
      </Group>
    );

    if (saleStatus === SaleStatus.PAUSED) {
      return null;
    }

    if (
      [SaleStatus.ENDED, SaleStatus.SOLD_OUT].includes(saleStatus) ||
      isSoldOut ||
      isTokenSold
    ) {
      return isMarketplaceEnabledForCollectionChain ? (
        <Button
          ref={ref as Ref<HTMLButtonElement>}
          leftIcon={isAnySeriesMint ? undefined : <OpenSeaIcon />}
          onClick={() => {
            document
              .getElementById("marketplace-section")
              ?.scrollIntoView({ behavior: "smooth" });
            trackViewOnMarketplace();
          }}
          {...rest}
          color={OUTLINE_COLOR}
        >
          View full collection
        </Button>
      ) : !isTokenSold ? (
        <Button ref={ref} {...rest} disabled size="lg">
          Unavailable
        </Button>
      ) : (
        <></>
      );
    }

    const isLoading =
      tokenId != undefined &&
      mintingSeriesTokenId != null &&
      tokenId === mintingSeriesTokenId;

    // false positive risk / race condition on collections service,
    // means we don't want to block on "LIMIT_REACHED" just yet
    //
    // TODO: https://linear.app/highlight-xyz/issue/ENG-3620/[collections]-discuss-investigate-adding-an-onchainstatus-to-mint
    //
    // DO NOT CHECK -> userStatus === UserStatus.LIMIT_REACHED
    if (
      SaleStatus.NOT_STARTED === saleStatus ||
      collectionStatus !== CollectionStatus.LIVE
    ) {
      if (
        tokens[0].image ||
        collection.seriesImages?.coverImageUrl ||
        collection.seriesImages?.urls[0] ||
        collection.generativeDetails?.logoUrl
      ) {
        return (
          <AddToCalendarButton
            ref={ref}
            w="100%"
            size="xl"
            name={collection.name}
            creator={collection.creatorEns ?? undefined}
            date={mintVector.start}
            collectionId={collection.id}
            collection={collection}
            imageUrl={
              collection.flagVariations.shloms404UI
                ? SHLOMS_404_FALLBACK_IMG_URL
                : tokens[0].image ||
                  collection.seriesImages?.coverImageUrl ||
                  collection.seriesImages?.urls[0] ||
                  collection.generativeDetails?.logoUrl ||
                  ""
            }
          />
        );
      }

      return (
        <Button ref={ref} {...rest} disabled size="lg">
          Unavailable
        </Button>
      );
    }

    if (!authenticated) {
      return showConnectButton ? <SignInButton ref={ref} {...rest} /> : null;
    }

    if (isSponsoredMint) {
      rest.color = AFFIRMATIVE_COLOR;
      rest.leftIcon = <SponsoredIcon />;
    }

    if (isLocked) {
      return (
        <Button
          ref={ref}
          {...rest}
          disabled={!showGateRequirementsModal}
          onClick={() =>
            handleShowGateRequirementsModal(collection, mintVector)
          }
        >
          {LockedBody}
        </Button>
      );
    }

    if (enableBurnAndRedeem) {
      if (!hasEnoughToBurnAndRedeem) {
        return (
          <Button ref={ref} {...rest} disabled>
            {LockedBody}
          </Button>
        );
      } else {
        return (
          <Stack spacing={8} w="100%" align="center">
            <Stack
              className={classes.buttonContainer}
              spacing={12}
              align="center"
              w="100%"
            >
              <ApproveERC1155Button
                initialButtonLabel="Burn tokens & mint"
                size="lg"
                showErrorIcon={showErrorIcon}
                onMintClick={() => onClickHandler(chainId)}
                showNumberMintsInput={showNumberMintsInput}
                {...rest}
              />
              {showMintFee && chain?.mintFee && !mintFeeWaived && (
                <MintFee numTokensToMint={numTokensToMint} />
              )}
            </Stack>

            {!showWarningModals && !hasEnoughMoney && (
              <ErrorBox message="Insufficient funds" small={!showErrorIcon} />
            )}
          </Stack>
        );
      }
    }

    if (isCollectorChoiceMint && !tokenId) {
      return (
        <Button
          ref={ref}
          {...rest}
          onClick={openMarketplaceModalHandler}
          size="lg"
        >
          Select token to mint
        </Button>
      );
    }
    if (
      !showWarningModals &&
      transactionState?.error &&
      transactionState.error.cause &&
      transactionState.error.cause.code !==
        LIFECYCLE_ERROR_CODES.USER_DENIED_TX &&
      !insufficientFundsError
    ) {
      return (
        <Stack spacing={8}>
          <Stack
            className={classes.buttonContainer}
            spacing={12}
            align="center"
          >
            <AppendNumMintsInput show={showNumberMintsInput}>
              <Button ref={ref} {...rest} disabled>
                {resolvedMintLabel}
              </Button>
            </AppendNumMintsInput>
            {showMintFee && chain?.mintFee && !mintFeeWaived && (
              <MintFee numTokensToMint={numTokensToMint} />
            )}
          </Stack>
          <ErrorBox
            message={
              insuficientAllowanceError
                ? "Insufficient allowance. Refresh and try again."
                : "There was an error, please try again later"
            }
            small={!showErrorIcon}
          />
        </Stack>
      );
    }

    if (
      transactionState &&
      !!transactionState.args &&
      !insufficientFundsError
    ) {
      if (transactionState.type === TransactionStateType.SignTxRejected) {
        return (
          <Stack spacing={8} sx={{ flex: 1 }}>
            {showApproveErc20Button ? (
              <ApproveErc20Button
                initialButtonLabel={mintLabelText}
                size="lg"
                onMintClick={() => onClickHandler(chainId)}
                {...rest}
                disabledMint={disabledMint}
                showMintFee={showMintFee}
                showWarningModals={showWarningModals}
                hasEnoughMoney={hasEnoughMoney}
                showErrorIcon={showErrorIcon}
                showNumberMintsInput={showNumberMintsInput}
              />
            ) : (
              <Stack
                className={classes.buttonContainer}
                spacing={12}
                align="center"
              >
                <AppendNumMintsInput show={showNumberMintsInput}>
                  <Button
                    ref={ref}
                    {...rest}
                    onClick={() => {
                      onClickHandler(chainId);
                    }}
                    disabled={
                      !showWarningModals &&
                      userStatus === UserStatus.LIMIT_REACHED
                    }
                  >
                    {resolvedMintLabel}
                  </Button>
                </AppendNumMintsInput>
                {showMintFee && chain?.mintFee && !mintFeeWaived && (
                  <MintFee numTokensToMint={numTokensToMint} />
                )}
              </Stack>
            )}
            {!showWarningModals && (
              <ErrorBox
                message={
                  isSponsoredMint
                    ? "Error claiming token, try again later"
                    : "Please confirm in your wallet"
                }
                small={!showErrorIcon}
              />
            )}
          </Stack>
        );
      } else if (
        ![
          TransactionStateType.Done,
          TransactionStateType.ChainSwitchError,
        ].includes(transactionState.type)
      ) {
        return (
          <Stack
            className={classes.buttonContainer}
            spacing={12}
            align="center"
          >
            <AppendNumMintsInput show={showNumberMintsInput}>
              <Button ref={ref} {...rest} disabled loading={isLoading}>
                {isCompact
                  ? resolvedMintLabel
                  : stateLabels[transactionState.type] ??
                    "Minting in progress..."}
              </Button>
            </AppendNumMintsInput>
            {showMintFee && chain?.mintFee && !mintFeeWaived && (
              <MintFee numTokensToMint={numTokensToMint} />
            )}
          </Stack>
        );
      }
    }

    if (showApproveErc20Button) {
      return (
        <ApproveErc20Button
          initialButtonLabel={mintLabelText}
          size="lg"
          onMintClick={() => onClickHandler(chainId)}
          {...rest}
          disabledMint={disabledMint}
          showMintFee={showMintFee}
          showWarningModals={showWarningModals}
          hasEnoughMoney={hasEnoughMoney}
          showErrorIcon={showErrorIcon}
          showNumberMintsInput={showNumberMintsInput}
        />
      );
    }

    const disableMintButton =
      disabledMint ||
      !hasEnoughMoney ||
      (!showWarningModals && userStatus === UserStatus.LIMIT_REACHED);

    return (
      <Stack spacing={8} w="100%">
        {numMintsLeft > 0 ? (
          <Group spacing={12} noWrap>
            <Stack
              className={classes.buttonContainer}
              spacing={12}
              align="center"
            >
              {enableCrossChainMint &&
              !mintVector.consumerData &&
              !mintVector.sponsored &&
              !isErc20CurrencyMint ? (
                <AppendNumMintsInput show={showNumberMintsInput}>
                  <CrossChainMintButton
                    ref={ref}
                    userStatus={userStatus}
                    showWarningModals={showWarningModals}
                    disabledMint={!!disabledMint}
                    hasEnoughMoney={hasEnoughMoney}
                    chainId={chainId}
                    label={resolvedMintLabel}
                    tokenId={tokenId}
                    onClickHandler={onClickHandler}
                    {...rest}
                  />
                </AppendNumMintsInput>
              ) : (
                <AppendNumMintsInput show={showNumberMintsInput}>
                  <Button
                    ref={ref}
                    {...rest}
                    onClick={() => {
                      onClickHandler(chainId);
                    }}
                    disabled={disableMintButton}
                  >
                    {resolvedMintLabel}
                  </Button>
                </AppendNumMintsInput>
              )}
              {showMintFee && chain?.mintFee && !mintFeeWaived && (
                <MintFee numTokensToMint={numTokensToMint} />
              )}
            </Stack>
          </Group>
        ) : (
          <Stack className={classes.buttonContainer} spacing={12}>
            {enableCrossChainMint &&
            !mintVector.consumerData &&
            !mintVector.sponsored &&
            !isErc20CurrencyMint ? (
              <AppendNumMintsInput show={showNumberMintsInput}>
                <CrossChainMintButton
                  userStatus={userStatus}
                  showWarningModals={showWarningModals}
                  disabledMint={!!disabledMint}
                  hasEnoughMoney={hasEnoughMoney}
                  chainId={chainId}
                  label={mintLabel}
                  tokenId={tokenId}
                  onClickHandler={onClickHandler}
                  {...rest}
                />
              </AppendNumMintsInput>
            ) : (
              <AppendNumMintsInput show={showNumberMintsInput}>
                <Button
                  ref={ref}
                  {...rest}
                  onClick={() => {
                    onClickHandler(chainId);
                  }}
                  disabled={
                    !showWarningModals &&
                    userStatus === UserStatus.LIMIT_REACHED
                  }
                >
                  {resolvedMintLabel}
                </Button>
              </AppendNumMintsInput>
            )}
            {showMintFee && chain?.mintFee && !mintFeeWaived && (
              <MintFee numTokensToMint={numTokensToMint} />
            )}
          </Stack>
        )}
        {!showWarningModals && (
          <>
            {!hasEnoughMoney &&
              (!enableCrossChainMint || isErc20CurrencyMint) && (
                <ErrorBox message="Insufficient funds" small={!showErrorIcon} />
              )}
            {errorMsg && <ErrorBox message={errorMsg} small={!showErrorIcon} />}
          </>
        )}
      </Stack>
    );
  }
);

export default MintCardButton;
