import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { ArrowUp, ArrowDown, InfoCircleFill, } from "@hl/base-components/lib/assets/icons.generated";
import ModalSection from "@hl/base-components/lib/modal/ModalSection";
import { OUTLINE_COLOR, SECONDARY_COLOR, } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Button, Space, Text, ActionIcon, Grid, Group, Anchor, Tooltip, } from "@mantine/core";
import { zeroAddress } from "viem";
import { useNetwork } from "wagmi";
import { EXTERNAL_URL } from "../../config";
import { networkLookup } from "../../utils/blockExplorer";
import { getCurrencySymbol, getNativeCurrencyIcon } from "../../utils/currency";
import { useAuth } from "../auth";
import { FEATURE_FLAGS, useFeatureFlags } from "../auth/hooks";
import { useSupportedChains } from "../chain";
import ErrorText from "../error/ErrorText";
import CryptoCurrencyInput from "../input/CryptoCurrencyInput";
import { useModalStack } from "../modal";
import BlockchainSelect, { CurrencyType } from "./BlockchainSelect";
import { SwapModal } from "./SwapModal";
import { useCurrencyBalance } from "./useCurrencyBalance";
import { useSwapCurrency } from "./useSwapCurrency";
export const Balance = ({ small = false }) => {
    var _a;
    const { authenticated, walletAddress } = useAuth();
    const network = useNetwork();
    const [walletWrappedCurrency, setWalletWrappedCurrency] = useState(null);
    const { pushModal } = useModalStack();
    const enableSwapCurrency = useFeatureFlags(FEATURE_FLAGS.ENABLE_SWAP_CURRENCY);
    const walletChainId = (_a = network === null || network === void 0 ? void 0 : network.chain) === null || _a === void 0 ? void 0 : _a.id;
    const { supportedChains, loading: loadingChains, getWrappedCurrency, getChain, } = useSupportedChains(!walletAddress || !authenticated || !walletChainId);
    // Initialize wrappedCurrency
    useEffect(() => {
        if (!walletChainId || !supportedChains.length) {
            return setWalletWrappedCurrency(null);
        }
        const wrappedCurrency = getWrappedCurrency(walletChainId);
        if (!wrappedCurrency)
            return setWalletWrappedCurrency(null);
        setWalletWrappedCurrency({
            address: wrappedCurrency.address,
            symbol: wrappedCurrency.symbol,
            type: CurrencyType.WRAPPED,
        });
    }, [walletChainId, supportedChains]);
    const { balanceRoundedSmall: nativeBalanceRounded, refetch: refetchNativeBalance, isFetching: isFetchingNativeBalance, } = useCurrencyBalance({ walletAddress, chainId: walletChainId });
    const { balanceRoundedSmall: wrappedBalanceRounded, refetch: refetchWrappedBalance, isFetching: isFetchingWrappedBalance, } = useCurrencyBalance({
        walletAddress,
        chainId: walletChainId,
        currency: walletWrappedCurrency,
    });
    const currencySymbol = walletChainId ? getCurrencySymbol(walletChainId) : "";
    const walletNetworkChain = walletChainId
        ? networkLookup(walletChainId)
        : undefined;
    const open = useCallback(() => {
        if (!walletAddress || !walletChainId)
            return;
        if (enableSwapCurrency) {
            pushModal(_jsx(SwapModal, { supportedChains: supportedChains, walletAddress: walletAddress, walletWrappedCurrency: walletWrappedCurrency, getChain: getChain, walletChainId: walletChainId, isFetchingBalance: isFetchingNativeBalance || isFetchingWrappedBalance, small: small, refetchBalance: async () => {
                    await Promise.all([
                        refetchNativeBalance(),
                        refetchWrappedBalance(),
                    ]);
                } }), {
                fullScreen: small,
                size: "md",
                title: `Exchange currency across chains`,
            });
        }
        else {
            pushModal(_jsx(BalanceModal, { supportedChains: supportedChains, walletAddress: walletAddress, walletWrappedCurrency: walletWrappedCurrency, getChain: getChain, walletChainId: walletChainId, isFetchingBalance: isFetchingNativeBalance || isFetchingWrappedBalance, small: small, refetchBalance: async () => {
                    await Promise.all([
                        refetchNativeBalance(),
                        refetchWrappedBalance(),
                    ]);
                } }), {
                fullScreen: small,
                size: "md",
                title: `Wrapping / Unwrapping ${small ? "" : currencySymbol}`,
            });
        }
    }, [
        pushModal,
        currencySymbol,
        walletAddress,
        walletWrappedCurrency,
        walletChainId,
        supportedChains,
        isFetchingNativeBalance,
        isFetchingWrappedBalance,
        enableSwapCurrency,
    ]);
    if (loadingChains ||
        !walletAddress ||
        !authenticated ||
        !walletChainId ||
        isFetchingNativeBalance ||
        isFetchingWrappedBalance) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(_Fragment, { children: small ? (_jsxs(Group, { position: "apart", px: 12, py: 10, onClick: open, children: [_jsx(Text, { size: "sm", children: "Balance" }), _jsxs(Text, { size: "sm", color: TEXT_COLOR.SECONDARY, children: [nativeBalanceRounded, " ", currencySymbol] })] })) : (_jsx(Tooltip, { label: "Exchange currency across chains", children: _jsxs(Button, { color: OUTLINE_COLOR, onClick: open, leftIcon: (walletNetworkChain === null || walletNetworkChain === void 0 ? void 0 : walletNetworkChain.type)
                    ? getNativeCurrencyIcon(walletNetworkChain.type, 16)
                    : undefined, children: [nativeBalanceRounded, " ", currencySymbol, walletWrappedCurrency
                        ? ` | ${wrappedBalanceRounded} ${walletWrappedCurrency.symbol}`
                        : ""] }) })) }));
};
const BalanceModal = ({ small = false, refetchBalance, walletChainId, walletAddress, walletWrappedCurrency, supportedChains, getChain, isFetchingBalance, }) => {
    const [value, setValue] = useState(0);
    const [fromCurrency, setFromCurrency] = useState(null);
    const [toCurrency, setToCurrency] = useState(null);
    const [fromChain, setFromChain] = useState(null);
    const [toChain, setToChain] = useState(null);
    const { popModal } = useModalStack();
    // Initialize chains
    useEffect(() => {
        if (!walletChainId || !supportedChains.length) {
            setFromChain(null);
            setToChain(null);
            return;
        }
        let defaultChain = getChain(walletChainId);
        if (!(defaultChain === null || defaultChain === void 0 ? void 0 : defaultChain.wrappedCurrency)) {
            defaultChain = supportedChains[0];
        }
        if (!fromChain)
            setFromChain(defaultChain);
        if (!toChain)
            setToChain(defaultChain);
    }, [walletChainId, supportedChains]);
    // Initialize fromCurrency and toCurrency addresses
    useEffect(() => {
        if (!fromCurrency && walletChainId) {
            setFromCurrency({
                address: zeroAddress,
                symbol: getCurrencySymbol(walletChainId),
                type: CurrencyType.NATIVE,
            });
        }
        if (!toCurrency && walletWrappedCurrency) {
            setToCurrency(walletWrappedCurrency);
        }
    }, [walletWrappedCurrency, walletChainId]);
    const { handleSwap, error, buttonLoading, buttonLabel, clearError } = useSwapCurrency({
        toCurrency,
        toChain,
        fromChain,
        value,
        walletAddress,
        fromCurrency,
        tradeType: "EXACT_OUTPUT",
        onFinish: async () => {
            await refetchBalance();
            onClose();
        },
    });
    const onClose = useCallback(() => {
        setValue(0);
        clearError();
        popModal();
    }, [popModal, clearError]);
    const handleReverseSwapOnClick = useCallback(() => {
        const fromCurrencyAddress = fromCurrency;
        setFromCurrency(toCurrency);
        setToCurrency(fromCurrencyAddress);
    }, [fromCurrency, toCurrency]);
    const handleBlockchainSelect = useCallback((setChain) => ({ chain, wrappedCurrency, nativeCurrency, }) => {
        setChain(chain);
        setFromChain(chain);
        setToChain(chain);
        const fromCurrencyIsNative = !fromCurrency || (fromCurrency === null || fromCurrency === void 0 ? void 0 : fromCurrency.type) === CurrencyType.NATIVE;
        setFromCurrency(fromCurrencyIsNative ? nativeCurrency : wrappedCurrency);
        setToCurrency(fromCurrencyIsNative ? wrappedCurrency : nativeCurrency);
    }, [fromCurrency]);
    if (!fromChain || !toChain) {
        return _jsx(_Fragment, {});
    }
    const currencySymbol = getCurrencySymbol(fromChain.chainId);
    return (_jsxs("form", { children: [_jsxs(Grid, { align: "center", justify: "center", children: [_jsx(Grid.Col, { span: 1, pr: 0, hidden: small, children: _jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: "From" }) }), _jsx(Grid.Col, { span: 6, children: _jsx(CryptoCurrencyInput, { missingChainIdPlaceholder: "", chainId: fromChain.chainId, onChange: (value) => {
                                setValue(value || 0);
                            }, value: value, customCurrencySymbol: fromCurrency === null || fromCurrency === void 0 ? void 0 : fromCurrency.symbol }) }), _jsx(Grid.Col, { span: small ? 6 : 5, children: _jsx(BlockchainSelect, { searchable: !small, supportedChains: supportedChains, value: fromChain.chainId.toString(), onChainSelect: handleBlockchainSelect(setFromChain) }) }), _jsx(Grid.Col, { span: 12, children: _jsx(Group, { align: "center", w: "100%", position: "center", children: _jsxs(ActionIcon, { size: "xl", onClick: handleReverseSwapOnClick, color: SECONDARY_COLOR, children: [_jsx(ArrowDown, { width: 16, height: 16 }), _jsx(ArrowUp, { width: 16, height: 16 })] }) }) }), _jsx(Grid.Col, { span: 1, pr: 0, hidden: small, children: _jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: "To" }) }), _jsx(Grid.Col, { span: 6, children: _jsx(CryptoCurrencyInput, { missingChainIdPlaceholder: "", chainId: toChain.chainId, onChange: (value) => setValue(value || 0), value: value, customCurrencySymbol: toCurrency === null || toCurrency === void 0 ? void 0 : toCurrency.symbol }) }), _jsx(Grid.Col, { span: small ? 6 : 5, children: _jsx(BlockchainSelect, { searchable: !small, supportedChains: supportedChains, value: toChain.chainId.toString(), onChainSelect: handleBlockchainSelect(setToChain) }) })] }), _jsx(Space, { h: 8 }), _jsxs(ModalSection, { children: [_jsx(Button, { fullWidth: true, onClick: handleSwap, size: "xl", loading: buttonLoading || isFetchingBalance, disabled: value === 0, children: buttonLabel ||
                            (!fromCurrency || (fromCurrency === null || fromCurrency === void 0 ? void 0 : fromCurrency.type) === CurrencyType.NATIVE
                                ? `Wrap ${currencySymbol}`
                                : `Unwrap ${currencySymbol}`) }), _jsx(ErrorText, { error: error, mt: 6, align: "center" }), _jsx(Space, { h: "sm" }), _jsx(Anchor, { href: EXTERNAL_URL.SWAP_CURRENCIES_DOC, underline: false, target: "_blank", children: _jsxs(Group, { align: "center", spacing: 6, position: "center", children: [_jsx(InfoCircleFill, { width: 17, height: 17, cursor: "pointer" }), _jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "Learn more about wrapping/unwrapping currencies" })] }) })] })] }));
};
