/** App Features */
export var AppFeature;
(function (AppFeature) {
    AppFeature["TokenGate"] = "TokenGate";
    AppFeature["TokenMint"] = "TokenMint";
})(AppFeature || (AppFeature = {}));
/** Type of Post Attachment. */
export var AttachmentType;
(function (AttachmentType) {
    AttachmentType["MEDIA"] = "MEDIA";
    AttachmentType["TOKEN"] = "TOKEN";
})(AttachmentType || (AttachmentType = {}));
/** Status of the Auction */
export var AuctionStatus;
(function (AuctionStatus) {
    AuctionStatus["Ended"] = "Ended";
    AuctionStatus["Fulfilled"] = "Fulfilled";
    AuctionStatus["InProgress"] = "InProgress";
    AuctionStatus["NotStarted"] = "NotStarted";
})(AuctionStatus || (AuctionStatus = {}));
/** Theme background type */
export var BackgroundType;
(function (BackgroundType) {
    BackgroundType["Gradient"] = "Gradient";
    BackgroundType["Solid"] = "Solid";
})(BackgroundType || (BackgroundType = {}));
/** Type of Benefit for the Token. */
export var BenefitType;
(function (BenefitType) {
    BenefitType["CONTENT_FEED"] = "CONTENT_FEED";
    BenefitType["CUSTOM"] = "CUSTOM";
    BenefitType["DISCORD"] = "DISCORD";
    BenefitType["RELEASE_PARTY"] = "RELEASE_PARTY";
    BenefitType["SHOPIFY"] = "SHOPIFY";
})(BenefitType || (BenefitType = {}));
/** Border type options for the community theme */
export var BorderType;
(function (BorderType) {
    BorderType["Pill"] = "Pill";
    BorderType["Rounded"] = "Rounded";
    BorderType["Straight"] = "Straight";
})(BorderType || (BorderType = {}));
/** Type of Marketplace to Buy on */
export var BuyMarketplace;
(function (BuyMarketplace) {
    BuyMarketplace["OpenSea"] = "OpenSea";
})(BuyMarketplace || (BuyMarketplace = {}));
/** Trigger Type for CapturePreview */
export var CapturePreviewTriggerType;
(function (CapturePreviewTriggerType) {
    CapturePreviewTriggerType["Delay"] = "Delay";
    CapturePreviewTriggerType["Function"] = "Function";
})(CapturePreviewTriggerType || (CapturePreviewTriggerType = {}));
/** Contract type to deploy */
export var CollectionContractType;
(function (CollectionContractType) {
    CollectionContractType["Editions"] = "Editions";
    CollectionContractType["EditionsSingle"] = "EditionsSingle";
    CollectionContractType["General"] = "General";
    CollectionContractType["Imported"] = "Imported";
})(CollectionContractType || (CollectionContractType = {}));
/** Sorting direction of the data */
export var CollectionSortDirection;
(function (CollectionSortDirection) {
    CollectionSortDirection["ASC"] = "ASC";
    CollectionSortDirection["DESC"] = "DESC";
})(CollectionSortDirection || (CollectionSortDirection = {}));
/** Sortable fields of a collection */
export var CollectionSortableField;
(function (CollectionSortableField) {
    CollectionSortableField["ACTIVE"] = "ACTIVE";
    CollectionSortableField["CREATED_DATE"] = "CREATED_DATE";
    CollectionSortableField["NAME"] = "NAME";
})(CollectionSortableField || (CollectionSortableField = {}));
/** Collection Standard Type */
export var CollectionStandardType;
(function (CollectionStandardType) {
    CollectionStandardType["OPENSEA"] = "OPENSEA";
})(CollectionStandardType || (CollectionStandardType = {}));
/** Status of the collection's deployment */
export var CollectionStatus;
(function (CollectionStatus) {
    CollectionStatus["DRAFT"] = "DRAFT";
    CollectionStatus["IN_PROGRESS"] = "IN_PROGRESS";
    CollectionStatus["LIVE"] = "LIVE";
})(CollectionStatus || (CollectionStatus = {}));
/** Options for filtering collections by status. */
export var CollectionStatusFilter;
(function (CollectionStatusFilter) {
    CollectionStatusFilter["ARCHIVED"] = "ARCHIVED";
    CollectionStatusFilter["DEPLOYED"] = "DEPLOYED";
    CollectionStatusFilter["DRAFT"] = "DRAFT";
    CollectionStatusFilter["INACTIVE"] = "INACTIVE";
    CollectionStatusFilter["LIVE"] = "LIVE";
})(CollectionStatusFilter || (CollectionStatusFilter = {}));
/** Color scheme type. */
export var ColorSchemeType;
(function (ColorSchemeType) {
    ColorSchemeType["dark"] = "dark";
    ColorSchemeType["light"] = "light";
    ColorSchemeType["system"] = "system";
})(ColorSchemeType || (ColorSchemeType = {}));
/** Community Notification Type */
export var CommunityNotificationType;
(function (CommunityNotificationType) {
    CommunityNotificationType["CONTRACT"] = "CONTRACT";
})(CommunityNotificationType || (CommunityNotificationType = {}));
/** The status of the community. */
export var CommunityStatus;
(function (CommunityStatus) {
    CommunityStatus["FROZEN"] = "FROZEN";
    CommunityStatus["IN_PROGRESS"] = "IN_PROGRESS";
    CommunityStatus["PENDING_FIX"] = "PENDING_FIX";
    CommunityStatus["PREVIEW"] = "PREVIEW";
    CommunityStatus["PUBLIC"] = "PUBLIC";
})(CommunityStatus || (CommunityStatus = {}));
/** Gate Condition Types */
export var ConditionType;
(function (ConditionType) {
    ConditionType["ALLOWLIST"] = "ALLOWLIST";
    ConditionType["ATTRIBUTE"] = "ATTRIBUTE";
    ConditionType["COLLECTION"] = "COLLECTION";
    ConditionType["CURRENCY"] = "CURRENCY";
    ConditionType["FARCASTER"] = "FARCASTER";
    ConditionType["MARKETING_ACCESS"] = "MARKETING_ACCESS";
    ConditionType["NFT"] = "NFT";
})(ConditionType || (ConditionType = {}));
/** Type of consumer mint vector type */
export var ConsumerMintVectorType;
(function (ConsumerMintVectorType) {
    ConsumerMintVectorType["CUSTOM"] = "CUSTOM";
    ConsumerMintVectorType["FARCASTER_FRAMES"] = "FARCASTER_FRAMES";
    ConsumerMintVectorType["HL_GASLESS"] = "HL_GASLESS";
    ConsumerMintVectorType["VERISART"] = "VERISART";
})(ConsumerMintVectorType || (ConsumerMintVectorType = {}));
/** Contract type - shared enum */
export var ContractEntityType;
(function (ContractEntityType) {
    ContractEntityType["EditionsDFS1155"] = "EditionsDFS1155";
    ContractEntityType["GenerativeSeries"] = "GenerativeSeries";
    ContractEntityType["Imported"] = "Imported";
    ContractEntityType["MultipleEditions"] = "MultipleEditions";
    ContractEntityType["MultipleEditionsDFS"] = "MultipleEditionsDFS";
    ContractEntityType["Series"] = "Series";
    ContractEntityType["SingleEdition"] = "SingleEdition";
    ContractEntityType["SingleEditionDFS"] = "SingleEditionDFS";
})(ContractEntityType || (ContractEntityType = {}));
/** The type of contract. */
export var ContractType;
(function (ContractType) {
    ContractType["Community"] = "Community";
    ContractType["TokenManager"] = "TokenManager";
})(ContractType || (ContractType = {}));
/** Theme Corners shape */
export var CornersShape;
(function (CornersShape) {
    CornersShape["Rounded"] = "Rounded";
    CornersShape["Square"] = "Square";
})(CornersShape || (CornersShape = {}));
/** Sort value for Creator Community Members. */
export var CreatorCommunityMembersSort;
(function (CreatorCommunityMembersSort) {
    CreatorCommunityMembersSort["BY_DATE"] = "BY_DATE";
    CreatorCommunityMembersSort["BY_DATE_REVERSE"] = "BY_DATE_REVERSE";
    CreatorCommunityMembersSort["BY_TOKENS_AMOUNT"] = "BY_TOKENS_AMOUNT";
    CreatorCommunityMembersSort["BY_TOKENS_AMOUNT_REVERSE"] = "BY_TOKENS_AMOUNT_REVERSE";
})(CreatorCommunityMembersSort || (CreatorCommunityMembersSort = {}));
/** Currency Standard Type */
export var CurrencyStandardType;
(function (CurrencyStandardType) {
    CurrencyStandardType["ERC20"] = "ERC20";
    CurrencyStandardType["NATIVE"] = "NATIVE";
})(CurrencyStandardType || (CurrencyStandardType = {}));
/** Currency symbol */
export var CurrencySymbol;
(function (CurrencySymbol) {
    CurrencySymbol["CIRCLE"] = "CIRCLE";
    CurrencySymbol["DEGEN"] = "DEGEN";
    CurrencySymbol["ENJOY"] = "ENJOY";
    CurrencySymbol["ETH"] = "ETH";
    CurrencySymbol["GRND"] = "GRND";
    CurrencySymbol["HIGHER"] = "HIGHER";
    CurrencySymbol["MATIC"] = "MATIC";
    CurrencySymbol["MNT"] = "MNT";
    CurrencySymbol["SHAPEPE"] = "SHAPEPE";
    CurrencySymbol["TIA"] = "TIA";
    CurrencySymbol["ZK"] = "ZK";
})(CurrencySymbol || (CurrencySymbol = {}));
/** Currency type */
export var CurrencyType;
(function (CurrencyType) {
    CurrencyType["ERC20"] = "ERC20";
    CurrencyType["NATIVE"] = "NATIVE";
})(CurrencyType || (CurrencyType = {}));
/** How much of the collection draft has been filled out */
export var DraftProgress;
(function (DraftProgress) {
    DraftProgress["ADDED_DETAILS"] = "ADDED_DETAILS";
    DraftProgress["ADDED_DISTRIBUTION"] = "ADDED_DISTRIBUTION";
    DraftProgress["ADDED_GEN_PREVIEW"] = "ADDED_GEN_PREVIEW";
    DraftProgress["ADDED_GEN_SIZE"] = "ADDED_GEN_SIZE";
    DraftProgress["ADDED_METADATA"] = "ADDED_METADATA";
    DraftProgress["AWAITING_SIGNATURE"] = "AWAITING_SIGNATURE";
    DraftProgress["DEPLOY_FAILED"] = "DEPLOY_FAILED";
    DraftProgress["FINISHED"] = "FINISHED";
    DraftProgress["INITIATED_DEPLOY"] = "INITIATED_DEPLOY";
    DraftProgress["SELECTED_TYPE"] = "SELECTED_TYPE";
    DraftProgress["TESTED_GEN_SCRIPT"] = "TESTED_GEN_SCRIPT";
})(DraftProgress || (DraftProgress = {}));
/** Type of entity or object the associated entity-id uniquely identifies. */
export var EntityType;
(function (EntityType) {
    EntityType["POST"] = "POST";
    EntityType["RELEASE"] = "RELEASE";
})(EntityType || (EntityType = {}));
/** Event Type */
export var EventType;
(function (EventType) {
    EventType["CompleteSignedMessage"] = "CompleteSignedMessage";
    EventType["ConnectWallet"] = "ConnectWallet";
    EventType["CreateGateEditor_EditContent"] = "CreateGateEditor_EditContent";
    EventType["CreateGateEditor_EditTheme"] = "CreateGateEditor_EditTheme";
    EventType["CreateGateEditor_ImportToken"] = "CreateGateEditor_ImportToken";
    EventType["CreateGateEditor_PublishClick"] = "CreateGateEditor_PublishClick";
    EventType["CreateGateEditor_SetConditions"] = "CreateGateEditor_SetConditions";
    EventType["CreatedGate"] = "CreatedGate";
    EventType["CreatedGate_HasAudioContentType"] = "CreatedGate_HasAudioContentType";
    EventType["CreatedGate_HasEmbedContentType"] = "CreatedGate_HasEmbedContentType";
    EventType["CreatedGate_HasHTMLContentType"] = "CreatedGate_HasHTMLContentType";
    EventType["CreatedGate_HasImageContentType"] = "CreatedGate_HasImageContentType";
    EventType["CreatedGate_HasTextContentType"] = "CreatedGate_HasTextContentType";
    EventType["CreatedGate_HasVideoContentType"] = "CreatedGate_HasVideoContentType";
    EventType["DeployCollection_ArweaveUploadComplete"] = "DeployCollection_ArweaveUploadComplete";
    EventType["DeployCollection_ArweaveUploadFailed"] = "DeployCollection_ArweaveUploadFailed";
    EventType["DeployCollection_CompletedSuccessfully"] = "DeployCollection_CompletedSuccessfully";
    EventType["DeployCollection_Deploy_Button_Clicked"] = "DeployCollection_Deploy_Button_Clicked";
    EventType["DeployCollection_MediaSelectedAudioType"] = "DeployCollection_MediaSelectedAudioType";
    EventType["DeployCollection_MediaSelectedImageType"] = "DeployCollection_MediaSelectedImageType";
    EventType["DeployCollection_MediaSelectedVideoType"] = "DeployCollection_MediaSelectedVideoType";
    EventType["DeployCollection_TransactionSigned"] = "DeployCollection_TransactionSigned";
    EventType["DeployCollection_Viewed"] = "DeployCollection_Viewed";
    EventType["DeployCollection_Web2ObjectsCreatedSuccessfully"] = "DeployCollection_Web2ObjectsCreatedSuccessfully";
    EventType["DeployCollection_Web2ObjectsFailedtoCreate"] = "DeployCollection_Web2ObjectsFailedtoCreate";
    EventType["EditToken_ChangeGateModalOpened"] = "EditToken_ChangeGateModalOpened";
    EventType["EditToken_ChangeGateSaved"] = "EditToken_ChangeGateSaved";
    EventType["EditToken_EditSaleModalOpened"] = "EditToken_EditSaleModalOpened";
    EventType["EditToken_EditSaleSaved"] = "EditToken_EditSaleSaved";
    EventType["EditToken_PauseSale"] = "EditToken_PauseSale";
    EventType["EditToken_UnpauseSale"] = "EditToken_UnpauseSale";
    EventType["EditToken_Viewed"] = "EditToken_Viewed";
    EventType["ExistingGateEditor"] = "ExistingGateEditor";
    EventType["TokenSalePage_MintButtonClicked"] = "TokenSalePage_MintButtonClicked";
    EventType["TokenSalePage_TransactionFailed"] = "TokenSalePage_TransactionFailed";
    EventType["TokenSalePage_TransactionSigned"] = "TokenSalePage_TransactionSigned";
    EventType["TokenSalePage_TransactionSucceeded"] = "TokenSalePage_TransactionSucceeded";
    EventType["TokenSalePage_Viewed"] = "TokenSalePage_Viewed";
    EventType["ViewGatedPage_Locked"] = "ViewGatedPage_Locked";
    EventType["ViewGatedPage_Unlocked"] = "ViewGatedPage_Unlocked";
})(EventType || (EventType = {}));
/** Evm type of the onchain id */
export var EvmOnChainIdType;
(function (EvmOnChainIdType) {
    EvmOnChainIdType["BYTES32"] = "BYTES32";
    EvmOnChainIdType["UINT256"] = "UINT256";
})(EvmOnChainIdType || (EvmOnChainIdType = {}));
/** Sale kind of the sale */
export var EvmTxSaleKind;
(function (EvmTxSaleKind) {
    EvmTxSaleKind["LISTING"] = "LISTING";
    EvmTxSaleKind["OFFER"] = "OFFER";
})(EvmTxSaleKind || (EvmTxSaleKind = {}));
/** Explore category */
export var ExploreCategory;
(function (ExploreCategory) {
    ExploreCategory["Auctions"] = "Auctions";
    ExploreCategory["Categories"] = "Categories";
    ExploreCategory["CommunityToken"] = "CommunityToken";
    ExploreCategory["Curated"] = "Curated";
    ExploreCategory["EndingSoon"] = "EndingSoon";
    ExploreCategory["Farcaster"] = "Farcaster";
    ExploreCategory["Free"] = "Free";
    ExploreCategory["New"] = "New";
    ExploreCategory["NewTrendingOnSecondary"] = "NewTrendingOnSecondary";
    ExploreCategory["PopularOnSecondary"] = "PopularOnSecondary";
    ExploreCategory["Tags"] = "Tags";
    ExploreCategory["Trending"] = "Trending";
    ExploreCategory["TrendingOnSecondary"] = "TrendingOnSecondary";
})(ExploreCategory || (ExploreCategory = {}));
/** Failure Unique Code */
export var FailureReasonCode;
(function (FailureReasonCode) {
    FailureReasonCode["FC_NOT_FOLLOWING_ACCOUNT"] = "FC_NOT_FOLLOWING_ACCOUNT";
    FailureReasonCode["FC_WALLET_NOT_CONNECTED"] = "FC_WALLET_NOT_CONNECTED";
    FailureReasonCode["INSUFFICIENT_NFT_BALANCE"] = "INSUFFICIENT_NFT_BALANCE";
    FailureReasonCode["NO_CREATOR_ACCESS"] = "NO_CREATOR_ACCESS";
    FailureReasonCode["NO_EMAIL_LINKED"] = "NO_EMAIL_LINKED";
    FailureReasonCode["PENDING_EMAIL_VERIFICATION"] = "PENDING_EMAIL_VERIFICATION";
    FailureReasonCode["SERVER_FAILURE"] = "SERVER_FAILURE";
})(FailureReasonCode || (FailureReasonCode = {}));
/** Gasless claim error code */
export var GaslessClaimErrorCode;
(function (GaslessClaimErrorCode) {
    GaslessClaimErrorCode["GAS_POOL_DEPLETED"] = "GAS_POOL_DEPLETED";
    GaslessClaimErrorCode["INTERNAL"] = "INTERNAL";
    GaslessClaimErrorCode["MINT_NOT_FUNDED"] = "MINT_NOT_FUNDED";
    GaslessClaimErrorCode["MINT_UNAVAILABLE"] = "MINT_UNAVAILABLE";
    GaslessClaimErrorCode["TOP_LEVEL_MINT_FEE_NOT_WAIVED"] = "TOP_LEVEL_MINT_FEE_NOT_WAIVED";
    GaslessClaimErrorCode["UNKNOWN"] = "UNKNOWN";
})(GaslessClaimErrorCode || (GaslessClaimErrorCode = {}));
/** Gasless claim status */
export var GaslessClaimStatus;
(function (GaslessClaimStatus) {
    GaslessClaimStatus["INITIATED"] = "INITIATED";
    GaslessClaimStatus["TX_CANCELLED"] = "TX_CANCELLED";
    GaslessClaimStatus["TX_COMPLETE"] = "TX_COMPLETE";
    GaslessClaimStatus["TX_REVERTED"] = "TX_REVERTED";
    GaslessClaimStatus["TX_SENT"] = "TX_SENT";
})(GaslessClaimStatus || (GaslessClaimStatus = {}));
/** Generative edition type */
export var GenerativeEditionType;
(function (GenerativeEditionType) {
    GenerativeEditionType["Limited"] = "Limited";
    GenerativeEditionType["Open"] = "Open";
})(GenerativeEditionType || (GenerativeEditionType = {}));
/** Token type to import */
export var ImportedTokenType;
(function (ImportedTokenType) {
    ImportedTokenType["ERC20"] = "ERC20";
    ImportedTokenType["ERC721"] = "ERC721";
    ImportedTokenType["ERC1155"] = "ERC1155";
    ImportedTokenType["Native"] = "Native";
})(ImportedTokenType || (ImportedTokenType = {}));
/** Event Kind for Live Feed */
export var LiveFeedEventKind;
(function (LiveFeedEventKind) {
    LiveFeedEventKind["highlightxyz"] = "highlightxyz";
    LiveFeedEventKind["marketplace"] = "marketplace";
    LiveFeedEventKind["transferActivity"] = "transferActivity";
})(LiveFeedEventKind || (LiveFeedEventKind = {}));
/** Event SubKind for Live Feed */
export var LiveFeedEventSubKind;
(function (LiveFeedEventSubKind) {
    LiveFeedEventSubKind["HighlightxyzMintManagerChooseTokenMint"] = "HighlightxyzMintManagerChooseTokenMint";
    LiveFeedEventSubKind["HighlightxyzMintManagerNumTokenMint"] = "HighlightxyzMintManagerNumTokenMint";
    LiveFeedEventSubKind["HighlightxyzObservabilityGenerativeSeriesDeployed"] = "HighlightxyzObservabilityGenerativeSeriesDeployed";
    LiveFeedEventSubKind["HighlightxyzObservabilityMultipleEditionsDeployed"] = "HighlightxyzObservabilityMultipleEditionsDeployed";
    LiveFeedEventSubKind["HighlightxyzObservabilitySeriesDeployed"] = "HighlightxyzObservabilitySeriesDeployed";
    LiveFeedEventSubKind["HighlightxyzObservabilitySingleEditionDeployed"] = "HighlightxyzObservabilitySingleEditionDeployed";
    LiveFeedEventSubKind["MarketplaceTokenSale"] = "MarketplaceTokenSale";
    LiveFeedEventSubKind["MarketplaceTokenTransfer"] = "MarketplaceTokenTransfer";
    LiveFeedEventSubKind["burn"] = "burn";
    LiveFeedEventSubKind["mint"] = "mint";
    LiveFeedEventSubKind["sale"] = "sale";
    LiveFeedEventSubKind["transfer"] = "transfer";
})(LiveFeedEventSubKind || (LiveFeedEventSubKind = {}));
/** Mint ID Type */
export var MintIdType;
(function (MintIdType) {
    MintIdType["ContractAddress"] = "ContractAddress";
    MintIdType["Vector"] = "Vector";
})(MintIdType || (MintIdType = {}));
/** Status of the Mint Vector */
export var MintVectorStatus;
(function (MintVectorStatus) {
    MintVectorStatus["Ended"] = "Ended";
    MintVectorStatus["InProgress"] = "InProgress";
    MintVectorStatus["NotStarted"] = "NotStarted";
})(MintVectorStatus || (MintVectorStatus = {}));
/** NFT Standard Type */
export var NftStandardType;
(function (NftStandardType) {
    NftStandardType["ERC721"] = "ERC721";
    NftStandardType["ERC1155"] = "ERC1155";
})(NftStandardType || (NftStandardType = {}));
/** Blockchain Network Types */
export var NetworkType;
(function (NetworkType) {
    NetworkType["ARBITRUM"] = "ARBITRUM";
    NetworkType["ARBITRUM_GOERLI"] = "ARBITRUM_GOERLI";
    NetworkType["ARBITRUM_RINKEBY"] = "ARBITRUM_RINKEBY";
    NetworkType["BASE"] = "BASE";
    NetworkType["BASE_GOERLI"] = "BASE_GOERLI";
    NetworkType["BASE_SEPOLIA"] = "BASE_SEPOLIA";
    NetworkType["CYBER"] = "CYBER";
    NetworkType["ETHEREUM"] = "ETHEREUM";
    NetworkType["FORMA"] = "FORMA";
    NetworkType["FOUNDRY"] = "FOUNDRY";
    NetworkType["GOERLI"] = "GOERLI";
    NetworkType["HARDHAT"] = "HARDHAT";
    NetworkType["KOVAN"] = "KOVAN";
    NetworkType["LOCALHOST"] = "LOCALHOST";
    NetworkType["MANTLE"] = "MANTLE";
    NetworkType["MUMBAI"] = "MUMBAI";
    NetworkType["OPTIMISM"] = "OPTIMISM";
    NetworkType["OPTIMISM_GOERLI"] = "OPTIMISM_GOERLI";
    NetworkType["OPTIMISM_KOVAN"] = "OPTIMISM_KOVAN";
    NetworkType["POLYGON"] = "POLYGON";
    NetworkType["RINKEBY"] = "RINKEBY";
    NetworkType["ROPSTEN"] = "ROPSTEN";
    NetworkType["SCROLL"] = "SCROLL";
    NetworkType["SEPOLIA"] = "SEPOLIA";
    NetworkType["SHAPE"] = "SHAPE";
    NetworkType["ZERO"] = "ZERO";
    NetworkType["ZK_SYNC_ERA"] = "ZK_SYNC_ERA";
    NetworkType["ZORA"] = "ZORA";
    NetworkType["ZORA_GOERLI"] = "ZORA_GOERLI";
})(NetworkType || (NetworkType = {}));
/** NFT contract standard. */
export var NftContractStandard;
(function (NftContractStandard) {
    NftContractStandard["ERC721"] = "ERC721";
    NftContractStandard["ERC1155"] = "ERC1155";
})(NftContractStandard || (NftContractStandard = {}));
/** Notification Status */
export var NotificationStatus;
(function (NotificationStatus) {
    NotificationStatus["FAILURE"] = "FAILURE";
    NotificationStatus["SUCCESS"] = "SUCCESS";
})(NotificationStatus || (NotificationStatus = {}));
/** Sorting options for marketplace orders (listings and offers) */
export var OrderSortOptions;
(function (OrderSortOptions) {
    OrderSortOptions["DATE_CREATED"] = "DATE_CREATED";
    OrderSortOptions["EXPIRING"] = "EXPIRING";
    OrderSortOptions["HIGHEST_ABOVE_FLOOR"] = "HIGHEST_ABOVE_FLOOR";
    OrderSortOptions["PRICE"] = "PRICE";
})(OrderSortOptions || (OrderSortOptions = {}));
/** Type of Page Attachment. */
export var PageAttachmentType;
(function (PageAttachmentType) {
    PageAttachmentType["MEDIA"] = "MEDIA";
})(PageAttachmentType || (PageAttachmentType = {}));
/** Page status */
export var PageStatus;
(function (PageStatus) {
    PageStatus["Draft"] = "Draft";
    PageStatus["Public"] = "Public";
})(PageStatus || (PageStatus = {}));
/** Supported Page Media Mime(s) */
export var PageSupportedMediaMime;
(function (PageSupportedMediaMime) {
    PageSupportedMediaMime["GIF"] = "GIF";
    PageSupportedMediaMime["JPEG"] = "JPEG";
    PageSupportedMediaMime["MP3"] = "MP3";
    PageSupportedMediaMime["MP4"] = "MP4";
    PageSupportedMediaMime["MPEG"] = "MPEG";
    PageSupportedMediaMime["PNG"] = "PNG";
    PageSupportedMediaMime["WAV"] = "WAV";
    PageSupportedMediaMime["WEBM"] = "WEBM";
    PageSupportedMediaMime["WEBP"] = "WEBP";
})(PageSupportedMediaMime || (PageSupportedMediaMime = {}));
/** Page Supported Media Types. */
export var PageSupportedMediaType;
(function (PageSupportedMediaType) {
    PageSupportedMediaType["AUDIO"] = "AUDIO";
    PageSupportedMediaType["IMAGE"] = "IMAGE";
    PageSupportedMediaType["VIDEO"] = "VIDEO";
})(PageSupportedMediaType || (PageSupportedMediaType = {}));
/** Portfolio Statuses */
export var PortfolioStatus;
(function (PortfolioStatus) {
    PortfolioStatus["DRAFT"] = "DRAFT";
    PortfolioStatus["LIVE"] = "LIVE";
})(PortfolioStatus || (PortfolioStatus = {}));
/** Price type of the collection */
export var PriceType;
(function (PriceType) {
    PriceType["DutchAuction"] = "DutchAuction";
    PriceType["EnglishAuction"] = "EnglishAuction";
    PriceType["Fixed"] = "Fixed";
    PriceType["RankedAuction"] = "RankedAuction";
})(PriceType || (PriceType = {}));
export var SafetyWarningLevel;
(function (SafetyWarningLevel) {
    SafetyWarningLevel["red"] = "red";
    SafetyWarningLevel["yellow"] = "yellow";
})(SafetyWarningLevel || (SafetyWarningLevel = {}));
/** Sale type of a mint vector */
export var SaleType;
(function (SaleType) {
    SaleType["PRIVATE"] = "PRIVATE";
    SaleType["PUBLIC"] = "PUBLIC";
})(SaleType || (SaleType = {}));
/** Section Types */
export var SectionType;
(function (SectionType) {
    SectionType["EditorData"] = "EditorData";
    SectionType["MintButton"] = "MintButton";
})(SectionType || (SectionType = {}));
/** Sorting direction of the data */
export var SortDirection;
(function (SortDirection) {
    SortDirection["ASC"] = "ASC";
    SortDirection["DESC"] = "DESC";
})(SortDirection || (SortDirection = {}));
/** Blockchain Supported Mainnets */
export var SupportedMainnets;
(function (SupportedMainnets) {
    SupportedMainnets["ARBITRUM"] = "ARBITRUM";
    SupportedMainnets["BASE"] = "BASE";
    SupportedMainnets["CYBER"] = "CYBER";
    SupportedMainnets["ETHEREUM"] = "ETHEREUM";
    SupportedMainnets["FORMA"] = "FORMA";
    SupportedMainnets["MANTLE"] = "MANTLE";
    SupportedMainnets["OPTIMISM"] = "OPTIMISM";
    SupportedMainnets["POLYGON"] = "POLYGON";
    SupportedMainnets["SCROLL"] = "SCROLL";
    SupportedMainnets["SHAPE"] = "SHAPE";
    SupportedMainnets["ZERO"] = "ZERO";
    SupportedMainnets["ZK_SYNC_ERA"] = "ZK_SYNC_ERA";
    SupportedMainnets["ZORA"] = "ZORA";
})(SupportedMainnets || (SupportedMainnets = {}));
/** Supported Media Mime(s) */
export var SupportedMediaMime;
(function (SupportedMediaMime) {
    SupportedMediaMime["GIF"] = "GIF";
    SupportedMediaMime["GLTF"] = "GLTF";
    SupportedMediaMime["JPEG"] = "JPEG";
    SupportedMediaMime["MP3"] = "MP3";
    SupportedMediaMime["MP4"] = "MP4";
    SupportedMediaMime["MPEG"] = "MPEG";
    SupportedMediaMime["PNG"] = "PNG";
    SupportedMediaMime["WAV"] = "WAV";
    SupportedMediaMime["WEBM"] = "WEBM";
    SupportedMediaMime["WEBP"] = "WEBP";
})(SupportedMediaMime || (SupportedMediaMime = {}));
/** Supported Media Types. */
export var SupportedMediaType;
(function (SupportedMediaType) {
    SupportedMediaType["AUDIO"] = "AUDIO";
    SupportedMediaType["GLTF"] = "GLTF";
    SupportedMediaType["IMAGE"] = "IMAGE";
    SupportedMediaType["VIDEO"] = "VIDEO";
})(SupportedMediaType || (SupportedMediaType = {}));
/** Supported Zipped Media Types */
export var SupportedZipMediaType;
(function (SupportedZipMediaType) {
    SupportedZipMediaType["ZIP"] = "ZIP";
})(SupportedZipMediaType || (SupportedZipMediaType = {}));
/** Contract type */
export var SystemContractType;
(function (SystemContractType) {
    SystemContractType["AuctionManager"] = "AuctionManager";
    SystemContractType["CollectionFactory"] = "CollectionFactory";
    SystemContractType["ConsensualNonTransferableTokenManage2"] = "ConsensualNonTransferableTokenManage2";
    SystemContractType["ConsensualNonTransferableTokenManager"] = "ConsensualNonTransferableTokenManager";
    SystemContractType["DiscreteDutchAuctionMechanic"] = "DiscreteDutchAuctionMechanic";
    SystemContractType["EditionsDFS1155Implementation"] = "EditionsDFS1155Implementation";
    SystemContractType["EditionsMetadataRenderer"] = "EditionsMetadataRenderer";
    SystemContractType["GaslessMechanic"] = "GaslessMechanic";
    SystemContractType["GenerativeSeriesImplementation"] = "GenerativeSeriesImplementation";
    SystemContractType["MinimalForwarder"] = "MinimalForwarder";
    SystemContractType["MintFeeOracle"] = "MintFeeOracle";
    SystemContractType["MintManager"] = "MintManager";
    SystemContractType["MultipleEditionsDFSImplementation"] = "MultipleEditionsDFSImplementation";
    SystemContractType["MultipleEditionsImplementation"] = "MultipleEditionsImplementation";
    SystemContractType["NonTransferableTokenManager"] = "NonTransferableTokenManager";
    SystemContractType["Observability"] = "Observability";
    SystemContractType["ObservabilityV2"] = "ObservabilityV2";
    SystemContractType["PermissionsRegistry"] = "PermissionsRegistry";
    SystemContractType["RandomRankedAuctionMechanic"] = "RandomRankedAuctionMechanic";
    SystemContractType["RandomSeriesImplementation"] = "RandomSeriesImplementation";
    SystemContractType["RankedAuctionMechanic"] = "RankedAuctionMechanic";
    SystemContractType["ReferralManager"] = "ReferralManager";
    SystemContractType["SeedBasedMintMechanic"] = "SeedBasedMintMechanic";
    SystemContractType["SeriesImplementation"] = "SeriesImplementation";
    SystemContractType["SingleEditionDFSImplementation"] = "SingleEditionDFSImplementation";
    SystemContractType["SingleEditionImplementation"] = "SingleEditionImplementation";
})(SystemContractType || (SystemContractType = {}));
/** Time unit of an interval */
export var TimeUnit;
(function (TimeUnit) {
    TimeUnit["HOURS"] = "HOURS";
    TimeUnit["MINUTES"] = "MINUTES";
})(TimeUnit || (TimeUnit = {}));
/** Distribution Type for the Token */
export var TokenDistributionType;
(function (TokenDistributionType) {
    TokenDistributionType["Auction"] = "Auction";
    TokenDistributionType["Mint"] = "Mint";
})(TokenDistributionType || (TokenDistributionType = {}));
/** Token Notification Type */
export var TokenNotificationType;
(function (TokenNotificationType) {
    TokenNotificationType["MINT"] = "MINT";
    TokenNotificationType["TRANSFER"] = "TRANSFER";
})(TokenNotificationType || (TokenNotificationType = {}));
/** Sorting options for marketplace tokens */
export var TokenSortOptions;
(function (TokenSortOptions) {
    TokenSortOptions["DATE_CREATED"] = "DATE_CREATED";
    TokenSortOptions["DATE_LISTED"] = "DATE_LISTED";
    TokenSortOptions["PRICE"] = "PRICE";
})(TokenSortOptions || (TokenSortOptions = {}));
/** Sortable fields of a collection token */
export var TokenSortableField;
(function (TokenSortableField) {
    TokenSortableField["DATE_CREATED"] = "DATE_CREATED";
    TokenSortableField["PRICE"] = "PRICE";
})(TokenSortableField || (TokenSortableField = {}));
/** Availability status of the Token. */
export var TokenStatus;
(function (TokenStatus) {
    TokenStatus["Minted"] = "Minted";
    TokenStatus["Pending"] = "Pending";
})(TokenStatus || (TokenStatus = {}));
/** Token type */
export var TokenType;
(function (TokenType) {
    TokenType["All"] = "All";
    TokenType["Benefit"] = "Benefit";
    TokenType["Collectible"] = "Collectible";
    TokenType["Membership"] = "Membership";
})(TokenType || (TokenType = {}));
/** what is the status of the onchain transaction */
export var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["MINED"] = "MINED";
    TransactionStatus["PENDING"] = "PENDING";
    TransactionStatus["REJECTED"] = "REJECTED";
})(TransactionStatus || (TransactionStatus = {}));
/** type of highlight onchain action associated with this transaction */
export var TransactionType;
(function (TransactionType) {
    TransactionType["EVM_721_AUCTION"] = "EVM_721_AUCTION";
    TransactionType["EVM_721_BID"] = "EVM_721_BID";
    TransactionType["EVM_721_MINT"] = "EVM_721_MINT";
    TransactionType["EVM_721_RANKED_AUCTION_BID"] = "EVM_721_RANKED_AUCTION_BID";
    TransactionType["EVM_721_RANKED_AUCTION_EARNINGS"] = "EVM_721_RANKED_AUCTION_EARNINGS";
    TransactionType["EVM_721_RANKED_AUCTION_RECLAIM_BID_FUNDS"] = "EVM_721_RANKED_AUCTION_RECLAIM_BID_FUNDS";
    TransactionType["EVM_APPROVE_TRANSFER"] = "EVM_APPROVE_TRANSFER";
    TransactionType["EVM_APPROVE_TRANSFER_WRAPPED"] = "EVM_APPROVE_TRANSFER_WRAPPED";
    TransactionType["EVM_CREATE_MECHANIC_VECTOR"] = "EVM_CREATE_MECHANIC_VECTOR";
    TransactionType["EVM_CREATE_MINT_VECTOR"] = "EVM_CREATE_MINT_VECTOR";
    TransactionType["EVM_CROSSCHAIN_BURN"] = "EVM_CROSSCHAIN_BURN";
    TransactionType["EVM_CURRENCY_ALLOWANCE"] = "EVM_CURRENCY_ALLOWANCE";
    TransactionType["EVM_DEPLOY_COLLECTION"] = "EVM_DEPLOY_COLLECTION";
    TransactionType["EVM_DEPOSIT_FUNDS"] = "EVM_DEPOSIT_FUNDS";
    TransactionType["EVM_DUTCH_AUCTION_CLAIM_FUNDS"] = "EVM_DUTCH_AUCTION_CLAIM_FUNDS";
    TransactionType["EVM_DUTCH_AUCTION_REBATE"] = "EVM_DUTCH_AUCTION_REBATE";
    TransactionType["EVM_MINT_CREATOR_RESERVES"] = "EVM_MINT_CREATOR_RESERVES";
    TransactionType["EVM_RANKED_AUCTION_BID"] = "EVM_RANKED_AUCTION_BID";
    TransactionType["EVM_SEED_REFRESH"] = "EVM_SEED_REFRESH";
    TransactionType["EVM_SET_BASE_URI"] = "EVM_SET_BASE_URI";
    TransactionType["EVM_SPONSOR_MINT"] = "EVM_SPONSOR_MINT";
    TransactionType["EVM_SWAP_CURRENCY"] = "EVM_SWAP_CURRENCY";
    TransactionType["EVM_TOKEN_SALE"] = "EVM_TOKEN_SALE";
    TransactionType["EVM_TOKEN_TRANSFER"] = "EVM_TOKEN_TRANSFER";
    TransactionType["EVM_UPDATE_DUTCH_AUCTION_VECTOR"] = "EVM_UPDATE_DUTCH_AUCTION_VECTOR";
    TransactionType["EVM_UPDATE_METADATA"] = "EVM_UPDATE_METADATA";
    TransactionType["EVM_UPDATE_MINT_VECTOR"] = "EVM_UPDATE_MINT_VECTOR";
    TransactionType["EVM_UPDATE_RANKED_AUCTION_VECTOR"] = "EVM_UPDATE_RANKED_AUCTION_VECTOR";
    TransactionType["EVM_UPDATE_ROYALTY"] = "EVM_UPDATE_ROYALTY";
})(TransactionType || (TransactionType = {}));
/** Type of update Action post reactions. */
export var UpdateActionType;
(function (UpdateActionType) {
    UpdateActionType["ADD"] = "ADD";
    UpdateActionType["REMOVE"] = "REMOVE";
})(UpdateActionType || (UpdateActionType = {}));
/** New collection type - temp until migration is over */
export var _CollectionType;
(function (_CollectionType) {
    _CollectionType["GenerativeSeries"] = "GenerativeSeries";
    _CollectionType["Imported"] = "Imported";
    _CollectionType["LimitedEdition"] = "LimitedEdition";
    _CollectionType["OneOfOne"] = "OneOfOne";
    _CollectionType["OpenEdition"] = "OpenEdition";
    _CollectionType["Series"] = "Series";
})(_CollectionType || (_CollectionType = {}));
