import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { lazy, Suspense } from "react";
import { 
// BrandHighlightLight,
BrandHighlightSmall as BrandJustText, BrandJustLogo, } from "@hl/base-components/lib/assets/icons.generated";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { createStyles } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
// animation library `lottie-react` is quite heavy, so we lazy load it
const AnimatedLogo = lazy(() => import("./logo-animated"));
export const useLogoStyles = createStyles((theme) => ({
    logoRoot: {
        display: "flex",
        color: theme.colors.primaryText,
        gap: 4,
        alignItems: "center",
        "svg g path": {
            stroke: `${theme.fn.themeColor(TEXT_COLOR.PRIMARY)} !important`,
        },
    },
    logoIcon: {
        width: 29,
    },
    logoText: {
        display: "block",
        width: 135,
        height: 15,
    },
}));
export const BrandStatic = () => {
    const { classes } = useLogoStyles();
    return (_jsxs("div", { className: classes.logoRoot, children: [_jsx(BrandJustLogo, { height: 17, width: 29 }), _jsx(BrandJustText, { className: classes.logoText })] }));
};
export const BrandAnimated = () => {
    const { classes, theme } = useLogoStyles();
    const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
    if (!isDesktop) {
        return _jsx(BrandStatic, {});
    }
    return (_jsx(Suspense, { fallback: _jsx(BrandStatic, {}), children: _jsxs("div", { className: classes.logoRoot, children: [_jsx("div", { className: classes.logoIcon, children: _jsx(AnimatedLogo, {}) }), _jsx(BrandJustText, { className: classes.logoText })] }) }));
};
export { BrandJustText, BrandJustLogo };
