import { gql } from "@apollo/client";
export const FlagVariationsFragmentDoc = gql `
  fragment FlagVariations on UserFlagVariations {
    enableResizedImages
    gatesDisablePageCreate
    gatesDisableTokenTestnets
    gatesEditorDisableHTMLPlugin
    collections721mEnableFeature
    contractsCollections721mCreate
    gatesAppShowOperationStatusHeader
    gatesEnableS3Upload
    embedsShowModal
    showLockedPreviewToggleInTools
    gateByTokenAttribute
    gatedMints
    importCollections
    createGateFromImportedCollection
    createGateFromImportedToken
    showAudienceTab
    createConditionFromWallet
    enableAuctions
    showAccountSettingsOnFrontend
    showCreateEmailGate
    showMintPageCollectors
    enableMultiplePortfolios
    enableInhotimCreateUx
    enableInhotimDrafts
    enableInhotimSeriesCollections
    useNewContractDeploy
    enableContractCustomization
    showDebugPages
    skipFrontendValidation
    showUploadZipPreview
    audienceTabV2
    showDarkModeSettings
    seriesMetadataUpdates
    enableGenSeriesCuratedOutput
    enableDutchAuctionsCreation
    enableRankedAuctions
    enableDirectMinting
    crossmintPayments
    crossmintSelfServe
    enableUserProfile
    enableNewToolsDashboard
    showGPUSupportToggle
    showCaptureSelectorType
    enableMintRevealPage
    showCollectionsContextMenu
    trendingModuleFreeMintsFilter
    trendingModule24HoursFilter
    trendingModuleMintedColumn
    marketplaceAllFeatures
    marketplaceCollectorsChoice
    zipIncreaseFileSizeLimit
    enableNavbarSearch
    enableEditCollectionDetails
    marketplacePageOptimization
    gateByFarcasterFollowers
    mintPageLoadOptimization
    createCollectionsWithoutMintVectors
    apolloMintPageUpdate
    enableCreatorRewards
    enableDegenCurrency
    showTestnetCurrencies
    enableReferrals
    enableSponsoredMints
    enableFormaCollections
    enableV3API
    enableTransfers
    calendarWatch
    enableTagsAndCategories
    curatedSpacesPreview
    enableCuratedSpacesAuthor
    enableHighlightedNotes
    enableTagsCategoriesForTestnet
    enable1155Support
    enableShapeCollectionCreation
    enableHomepageDarkMode
    enableRefreshTokenMetadata
    enableRefreshCollectionMetadata
    enableZeroCollectionCreation
    enableSwapCurrency
    tokenDetailsModalRouting
  }
`;
